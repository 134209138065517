html {
  width: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none !important;
}

#root{
  min-height: calc(100vh);
  background-color: rgb(251, 251, 253);
}

.draggable {
  width: 19%;
  margin-right: 8px;
  margin-bottom: 8px;
  border-radius: 4px;
  width: 24%;
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

.text-dots{
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

a, a:hover{
  text-decoration: none !important;
}

.expandable-list-group > a > .list-group-item{
  border-width: 0px !important;
  padding: .75rem 3rem;
}

.expandable-list-group > .list-group-item{
  border-width: 0px !important;
  padding: .75rem 3rem;
  color: #3E3F42;
}

.expandable-list-group > .list-group-item > span{
  cursor: pointer;
}

.expandable-list-group > a{
  color: #3E3F42 !important;
}

.open-expandable-list-group > .list-group-item:first-of-type{
  margin-top: 10px;
}

.open-expandable-list-group:after{
  content: "" !important;
  background: #EAEDF3 !important;
  position: absolute !important;
  bottom: 20px !important;
  top: 55px !important;
  left: 60px !important;
  width: 2px !important;
}

.last-expandable-list-group:after{
  content: "" !important;
  background: #EAEDF3 !important;
  position: absolute !important;
  bottom: 20px !important;
  top: 44px !important;
  left: 60px !important;
  width: 2px !important;
}

.first-list-group-item-open{
  z-index: 1;
}

.first-list-group-item-open:after{
  content: "" !important;
  background: #EAEDF3 !important;
  position: absolute !important;
  top: 0px !important;
  left: 0px !important;
  right: 0px !important;
  height: 46px;
  border-left: 21px solid #145BD3;
  z-index: -1;
}

.active-list-group-item{
  background-color: #F6F9FD !important;
  border-left: 20px solid #145BD3 !important;
}

.active-list-group-item > li > span{
  margin-left: -20px !important;
  color: #212529;
  font-weight: bolder;
}


.own-list-group > a > .list-group-item{
  border-width: 0px !important;
  padding: .75rem 3rem;
}

.own-list-group > a > .list-group-item:hover{
  background-color: #F6F9FD !important;
  border-left: 20px solid #145BD3 !important;
}

.own-list-group > a > .list-group-item:hover > span{
  /* border-left: 20px solid #145BD3 !important; */
  margin-left: -20px !important;
}

.own-list-group > .list-group-item{
  border-width: 0px !important;
  padding: .75rem 3rem;
  color: #3E3F42;
}

.own-list-group > .list-group-item:hover{
  border-width: 0px !important;
  padding: .75rem 3rem;
  color: #3E3F42;
  background-color: #F6F9FD !important;
  border-left: 20px solid #145BD3 !important;
}

.own-list-group > .list-group-item > span{
  cursor: pointer;
}

.own-list-group > a{
  color: #3E3F42 !important;
}

.open-own-list-group > .list-group-item:first-of-type{
  margin-top: 10px;
}

.open-own-list-group:after{
  content: "" !important;
  background: #EAEDF3 !important;
  position: absolute !important;
  bottom: 20px !important;
  top: 55px !important;
  left: 60px !important;
  width: 2px !important;
}

.last-own-list-group:after{
  content: "" !important;
  background: #EAEDF3 !important;
  position: absolute !important;
  bottom: 20px !important;
  top: 44px !important;
  left: 60px !important;
  width: 2px !important;
}

.first-list-group-item-open{
  z-index: 1;
}

.first-list-group-item-open:after{
  content: "" !important;
  background: #EAEDF3 !important;
  position: absolute !important;
  top: 0px !important;
  left: 0px !important;
  right: 0px !important;
  height: 46px;
  border-left: 21px solid #145BD3;
  z-index: -1;
}

.active-list-group-item{
  background-color: #F6F9FD !important;
  border-left: 20px solid #145BD3 !important;
}
.active-list-group-item:hover{
  background-color: #F6F9FD !important;
  border-left: 0px solid #145BD3 !important;
}
.active-list-group-item{
  background-color: #F6F9FD !important;
  border-left: 20px solid #145BD3 !important;
}

.active-list-group-item > li > span{
  margin-left: -20px !important;
  color: #212529;
  font-weight: bolder;
}

.searchbar-dropdown-toggle{
  background-color: white !important;
  border: 1px solid #ced4da !important;
  color: #3E3F42 !important;
  border-left-width: 0px !important;
}

.searchbar-dropdown-toggle:hover{
  background-color: white !important;
  color: #3E3F42 !important;
}

@media (max-width: 768px) {
  .draggable {
    width: 32% !important;
    margin-right: 7px;
  }
}


@media (max-width: 467px) {
  .draggable {
    width: 47% !important;
    margin-right: 7px;
  }
}

@media (max-width: 336px) {
  .navbar{
    padding: .5rem .2rem !important;
  }
}
@media (max-width: 302px) {
  .navbar{
    padding: .5rem .5rem;
  }
  .draggable {
    width: 100% !important;
    margin-right: 0px;
  }
}

.dragged {
  opacity: 0.7;
}

.placeholder {
  opacity: 0.5;
}

.List {
  width: 100%;
}

h6{
  color: #9EA0A5 !important
}

/* sterren */
.widget-svg {
  width: 37px !important;
  height: 37px !important;
}

/* datetimepicker */
.react-datetime-picker__wrapper {
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  padding: 0.375rem 0.2rem;
}
.react-datetime-picker__inputGroup__input,
.react-datetime-picker__calendar-button,
.react-datetime-picker__clear-button {
  color: #495057 !important;
}

@media (min-width: 1200px) {
  .container {
    max-width: 1600px !important;
  }
}
